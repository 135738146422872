// Language Text Direction
// --------------------------------------------------
// https://css-tricks.com/almanac/properties/d/direction/

*[dir="ltr"] {
    direction: ltr;
    unicode-bidi: embed;
}
*[dir="rtl"] {
    direction: rtl;
    unicode-bidi: embed;
}

// Inline Element Overrides
// -------------------------

bdo[dir="ltr"] {
    direction: ltr;
    unicode-bidi: bidi-override;
}
bdo[dir="rtl"] {
    direction: rtl;
    unicode-bidi: bidi-override;
}