// CSS Text Transforms
// -------------------------

.text-lowercase {
    text-transform: lowercase;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-capitalize {
    text-transform: capitalize;
}

// HTML5 Placeholder Text Transformation Classes
// --------------------------------------------------
// http://davidwalsh.name/html5-placeholder-css

// Firefox 19+
.placeholder-lowercase::-moz-placeholder {
    text-transform: lowercase;
}
.placeholder-uppercase::-moz-placeholder {
    text-transform: uppercase;
}
.placeholder-capitalize::-moz-placeholder {
    text-transform: capitalize;
}

// Firefox 18-4
.placeholder-lowercase:-moz-placeholder {
    text-transform: lowercase;
}
.placeholder-uppercase:-moz-placeholder {
    text-transform: uppercase;
}
.placeholder-capitalize:-moz-placeholder {
    text-transform: capitalize;
}

// WebKit
.placeholder-lowercase::-webkit-input-placeholder {
    text-transform: lowercase;
}
.placeholder-uppercase::-webkit-input-placeholder {
    text-transform: uppercase;
}
.placeholder-capitalize::-webkit-input-placeholder {
    text-transform: capitalize;
}

// IE 10+
.placeholder-lowercase::-ms-input-placeholder {
    text-transform: lowercase;
}
.placeholder-uppercase::-ms-input-placeholder {
    text-transform: uppercase;
}
.placeholder-capitalize::-ms-input-placeholder {
    text-transform: capitalize;
}